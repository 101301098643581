<template>
  <div class="home">

    <!-- 主体内容 -->
    <div class="main-content">
      <h1>欢迎来到漏洞扫描网站</h1>
      <p>This is the homepage of our vulnerability scanner website.</p>
      <router-link to="/scan" class="btn">Start Scan</router-link>
    </div>


    <div class="login-register-container" >

      <div class="l_box">

        <!-- 登录组件 -->
        <Login class="right" v-if="showLogin" @close="showLogin = false" />
        <!-- 注册组件 -->
        <Register class="right" v-if="!showLogin" @close="showLogin = true" />
      </div>

      <div class="login-register">
        <el-button class="login-register" type="info" @click="switchLogin" >切换</el-button>
      </div>

    </div>



    <!-- 底部 -->
    <div class="footer">
      <div class="sign">
        Copyright © 2024
        <svg class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
             width="200" height="200"><path d="M499.2 492.8c-4.266667-10.666667-2.133333-25.6 8.533333-34.133333 12.8-12.8 34.133333-12.8 46.933334 0 12.8 12.8 12.8 34.133333 0 46.933333-8.533333 8.533333-23.466667 10.666667-36.266667 6.4L251.733333 772.266667c25.6 17.066667 61.866667 12.8 83.2-8.533334 8.533333-8.533333 12.8-17.066667 17.066667-25.6l324.266667-138.666666c68.266667-23.466667 78.933333-34.133333 104.533333-100.266667l61.866667-147.2c-59.733333-57.6-117.333333-115.2-177.066667-172.8l-151.466667 59.733333c-68.266667 23.466667-78.933333 34.133333-104.533333 100.266667l-142.933333 315.733333c-10.666667 2.133333-19.2 8.533333-27.733334 14.933334-23.466667 21.333333-25.6 55.466667-8.533333 81.066666l268.8-258.133333z" fill="#E7BE40"></path><path d="M928 309.333333l-219.733333-213.333333c-12.8-12.8-36.266667-12.8-49.066667 0-12.8 12.8-12.8 34.133333 0 46.933333l219.733333 213.333334c12.8 12.8 36.266667 12.8 49.066667 0 14.933333-12.8 14.933333-34.133333 0-46.933334z" fill="#B7712E"></path><path d="M810.666667 682.666667c-27.733333 0-49.066667 19.2-55.466667 42.666666-34.133333 0-106.666667 4.266667-179.2 49.066667-100.266667 59.733333-260.266667 213.333333-388.266667 68.266667 6.4-8.533333 8.533333-19.2 8.533334-27.733334 0-29.866667-25.6-53.333333-55.466667-53.333333-29.866667 0-55.466667 23.466667-55.466667 53.333333s25.6 53.333333 55.466667 53.333334c8.533333 0 14.933333-2.133333 23.466667-4.266667 12.8 14.933333 108.8 128 264.533333 44.8 166.4-89.6 202.666667-147.2 326.4-147.2h6.4c8.533333 17.066667 27.733333 27.733333 49.066667 27.733333 29.866667 0 55.466667-23.466667 55.466666-53.333333S842.666667 682.666667 810.666667 682.666667z m0 0" fill="#5C7D8B"></path></svg>
        @Design By mochni
      </div>
    </div>
  </div>
</template>

<script>
import Login from '@/components/LoginPart.vue'; // 导入登录组件
import Register from '@/components/RegisterPart.vue'; // 导入注册组件

export default {
  name: 'HomePage',
  components: {
    Login,
    Register
  },
  data() {
    return {
      showLogin: true, // 控制是否显示登录组件
    };
  },
  methods: {
    switchLogin() {
      this.showLogin = !this.showLogin;
    }
  }
}
</script>

<style scoped>
/* 样式可以根据你的需求进行修改 */
.home {
  text-align: center;
  margin-top: 50px;
}

.icon{
  /*flex: 1;*/
  /*left: 0;*/
  width: 20px;
  height: 20px;
}

.topbar {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.login-register-container{
  display: flex;
  flex-direction: column;
  box-shadow: var(--el-box-shadow-light);
  position: absolute;
  width: 600px;
  height: 400px;
}
.l_box{
  box-shadow: var(--el-box-shadow-light);
  width: 598px;
  height: 360px;
}
.l_box, .login-register {
  margin: 2px;
}
.login-register{
  width: 99.4%;
  height: auto;
}

.main-content {
  margin-top: 20px;
}

.footer {
  background-color: #f5f5f5;
  position: fixed;
  bottom: 0;
  height: 50px;
  width: 100%;
}
</style>
