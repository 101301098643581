
import { createRouter, createWebHashHistory } from  'vue-router';
import Home from '../views/HomePage.vue';


const rs = [
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/admin/data',
    name: 'Data',
    component: () => import(/* webpackChunkName: "about" */ '../views/DataPage.vue')
  },
  {
    path: '/admin/home',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "about" */ '../views/AdminPage.vue')
  },
  {
    path: '/scan',
    name: 'Scan',
    component: () => import(/* webpackChunkName: "about" */ '../views/ScanPage.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes: rs,
})

export default router;

