<!--这是html模板-->
<template>
  <div>
    <div class="login"> <!--定义一个div标签，并设置class为login，class的作用是给标签设置css样式做定位，css可以通过这个class=login参数找到该标签-->
      <p class="title">登录界面 <!--设置标题-->
      </p>
      <div class="top">

        <el-form :model="loginForm"  ref="form"> <!--一个elementui的表单，并绑定了这个组件里面的数据集合里面的loginForm对象-->
          <el-form-item label="账号" prop="username">  <!--表单里面的内容，定义一行作为账号的输入-->
            <el-input placeholder="请输入账号" v-model="loginForm.username"></el-input>
            <!--elementui的输入框，设置placeholder属性为提示信息，输入内容绑定loginForm.username-->
          </el-form-item>
          <el-form-item label="密码" prop="password">  <!--密码-->
            <el-input type="password" placeholder="请输入密码" v-model="loginForm.password"></el-input>
            <!--elementui的输入框，设置密码类型，可以使得输入内容不可见，输入内容绑定loginForm.password-->
          </el-form-item>
        </el-form>

      </div>
      <div class="bottom">  <!-- 定义一个div标签存放按钮 -->
        <el-button type="primary" @click="login">登录</el-button> <!-- 定义elemnetui的按钮，设置被点击时执行login()方法 -->
      </div>
    </div>
  </div>
</template>

<!--这是注释的格式-->

<script>
import { encodeRSA, encryptAES } from "@/utils";  //从utils文件夹下的index.js中导入encryptAES和encodeRSA两个方法

export default {
  name: "LoginPart",
  data() {
    return {
      token_key: 'token',   //token的请求头字段
      loginForm: {  //登录表单信息
        username: '',
        password: ''
      },
      sendForm: {  //发送给后端的表单信息
        username: '',
        password: '',
        sign: ''  //签名，用来存RSA加密后的AES密钥
      }
    }
  },
  methods: {
    login: async function () {
      // login 方法，异步的方法
      let key = this.randomString(32);  //获取32位的随机字符串作为AES加密的密钥
      this.sendForm.sign = encodeRSA(key)     //将AES密钥加密后作为签名，放到发送表单的sign字段内
      this.sendForm.username = encryptAES(this.loginForm.username, key)  //将输入的用户名使用AES加密，作为发送表单的username字段
      this.sendForm.password = encryptAES(this.loginForm.password, key)  //将输入的密码使用AES加密，作为发送表单的password字段
      let errData = null; //定义一个错误信息变量
      // 发送POST请求，请求地址为/mcn/user/login，请求参数为sendForm
      const response = await this.$request.post('/mcn/user/login', this.sendForm).catch( (error) => {
            this.$message.error('登录失败' + error.response.data.message) //使用elementui的弹窗方法弹一个错误信息
            errData = error.response.data   //设置错误信息
          }
      )
      if (errData === null ) {
        //如果没有错误信息
        this.$message.success('登录成功')  //使用elementui的弹窗方法弹一个成功信息
        console.log(response.headers)  //控制台打印响应头
        localStorage.setItem('token',response.headers.token)  //本地存储响应头获取的token
        localStorage.setItem('user_role', response.headers.user_role)         //本地存储响应头获取的用户类型信息
        this.$router.push('/scan')      //登录成功，将前端路由地址设置成/scan页面
      } else {
        //存在错误信息，登录失败，清空表单内容
        this.clear()
      }

    },
    randomString(length) {
      //获取随机字符串方法，输入一个数字N，输出长度为N的随机字符串
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'; //随机字符串生成的数据集
      let result = ''; //结果变量
      const charactersLength = characters.length;  //获取数据集的长度
      for (let i = 0; i < length; i++) {  //for循环，循环N次
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        //每次循环都从数据集里面随机的拿一个字符并添加到结果变量内
      }
      //循环结束，result就是生成的随机字符串
      return result;  //得到的随机字符串返回
    },
    clear() {
      //清空表单
      this.loginForm.username = '' //表单的username清空
      this.loginForm.password = '' //表单的password清空
      this.loginForm.confirmPassword = '' //表单的确认密码清空
    }
  }

}
</script>


<style scoped lang="scss" >

.login {
.title{
  font-size: 20px;
  color:black;
  text-align: center;
  margin: 0 0 30px 0;
  z-index: 999;
};
border-radius: 8px;
padding: 20px 80px;
position: absolute;
border: 1px solid #ccc;

width: 90%;
height: auto;
color:#fff;
font-size: 20px;
font-weight: 60;
padding: 25px;


flex-direction: column;
align-items: center;
}

</style>
