import axios from 'axios';  //导入axios库

//通过axios创建一个request对象
const request = axios.create({
    baseURL: 'https://mcn.016imcn.love/api',  //设置请求的基础地址
    //baseURL: 'http://localhost:8016',
})

request.interceptors.request.use( function (config) {
    //设置拦截器  拦截所有的请求
    //拦截器的作用是在发送请求之前做一些处理
    config.headers.token = localStorage.getItem('token')
    //config是请求的配置信息
    //在拦截器中可以修改config中的信息
    //此处做的配置修改是设置请求头’token‘，从本地存储中获取token，并将其添加到请求头中
    return config;
    }
)

request.interceptors.response.use(
    //设置拦截器   拦截所有的响应
    //对响应的信息做个预处理
    function (response){
        return response;
        //如果响应是正常的（状态码2xx）就执行这里面的代码，将这个响应返回给前端调用的代码
    },
    function(error) {
        //如果这次http请求出现异常（状态码5xx）就执行这里面的代码
        if (error.response){
            //如果存在错误信息
            if (error.response.data.code === 50001 || error.response.data.code === 403){
                //如果错误信息的响应码是 50001 或 403 就执行下面的逻辑
                //50001 和 403 是 后端设置的响应码，50001代表token异常（过期/非法的token），403代表这个token没有访问权限（普通用户访问管理员接口）
                localStorage.removeItem('token') //删除本地存储的token
                location.href = '/'  //跳转至主页
            }
        }
        return Promise.reject(error);  //将错误信息也返回给前端
    }
)

//request对象的基本配置设置完毕

export default request;   //将request对象导出，外部JavaScript代码可以调用这个对象
