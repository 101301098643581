<template>
  <div id="app">
    <router-view></router-view>
<!--    <NewPage></NewPage>-->
  </div>
</template>

<script>


export default {

}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  top: 0;
  margin-top: 0px;
  margin-left: 0px;
  flex: 1;
}
</style>
