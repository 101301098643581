import JsEncrypt from "jsencrypt";
import CryptoJS from 'crypto-js';


/**
 * RSA加密方法
 * @param dataToEncrypt
 * @returns {string | false}
 */
export function encodeRSA(dataToEncrypt) {
        let pulicKey = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAqz5jgf/6u+3AIRqqpupJHeSTBbKdWNXAuK5pesLoxMQoMKKdmP3JJBwo3TMwC7C2finXb5ayiWR9SWO5ZOYqIR34pkPFN7XVSXP3MpIevIF93DXW26WeI/vtxnRaJaJTNy9MEyw50gZmQEN7ZMd4tHdBw5U71qAUM6Fp1918uLaZWgb+Pzj+j6bMYuY+rsgELG4hBt6YZqGyzszFx7SvsmEGnOBENDvkpXCK+XbAs2dn8V2wOsD83o4/zMqksiPvXbx8FY1TMxZ8Pfp23LPD0ivPCyk2bfRhoZ0OvPfGoUScc4IpAKGWqBuld5JBxf8MfkuSU2g0OWEyBwTwrOxU8wIDAQAB';
        let encrypt = new JsEncrypt();
        encrypt.setPublicKey(pulicKey);
        return  encrypt.encrypt(dataToEncrypt);
}


// 导出csv
export function exportCsv(columns, dataList, fileName) {
    let title = columns.map(item => item.title);
    let keyArray = columns.map(item => item.key);
    let str = [];
    str.push(title.join(',') + '\n');
    for (let i = 0; i < dataList.length; i++) {
        const temp = [];
        for (let j = 0; j < keyArray.length; j++) {
            temp.push(dataList[i][keyArray[j]]);
        }
        str.push(temp.join(',') + '\n');
    }
    console.log(str)
    let uri = 'data:text/csv;charset=utf-8,\ufeff' + encodeURIComponent(str.join(''));
    let downloadLink = document.createElement('a');
    downloadLink.href = uri;
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
}


/**
 * 获取随机字符串方法
 * @param length
 * @returns {string}
 */
export function randomString(length) {
    //随机字符串
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

/**
 * 生成密钥规范
 * @param {string} key 钥匙
 * @returns {string} 密钥规范
 */
function generateSecretKeySpec(key) {
    const sha = CryptoJS.SHA256(key);
    const truncatedBytes = CryptoJS.lib.WordArray.create(sha.words.slice(0, 4)); // Use first 16 bytes for AES key
    return CryptoJS.enc.Base64.stringify(truncatedBytes);
}

/**
 * 加密
 * @param {string} data 数据
 * @param {string} key  钥匙
 * @returns {string} 加密后的数据
 */
export function encryptAES(data, key) {
    const secretKeySpec = generateSecretKeySpec(key);
    const encrypted = CryptoJS.AES.encrypt(data, CryptoJS.enc.Base64.parse(secretKeySpec), {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
}
