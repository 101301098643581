<template>
  <div class="re" >
    <h1>注册</h1>
    <el-form ref="registerForm" :model="registerForm" label-width="100px" class="register-form">
      <el-form-item label="用户名" prop="username">
        <el-input v-model="registerForm.username" placeholder="请输入用户名"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input type="password" v-model="registerForm.password" placeholder="请输入密码"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="confirmPassword">
        <el-input type="password" v-model="registerForm.confirmPassword" placeholder="请确认密码"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="register">注册</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>

import { encodeRSA, encryptAES } from "@/utils";


export default {
  name: "RegisterPart",
  data() {
   return {
     registerForm: {
       username: '',
       password: '',
       confirmPassword: ''
     },
     sendForm: {
       username: '',
       password: '',
       sign: ''
     }

   }
  },
  methods: {
      register : async function () {
        if (this.registerForm.password !== this.registerForm.confirmPassword){
          this.$message.error('两次输入的密码不一致！')
          this.clear()
          return
        }

        let key = this.randomString(32);
        this.sendForm.sign = encodeRSA(key)
        this.sendForm.username = encryptAES(this.registerForm.username, key)
        this.sendForm.password = encryptAES(this.registerForm.password, key)

        // console.log(this.sendForm)
        // 请求登录接口
        console.log('请求注册接口')
        const response = await this.$request.post('/mcn/user/register', this.sendForm).catch(error =>
          this.$message.error(error.response.data.message)
        )
        console.log('发送请求成功')
        // console.log(response)
        // 判断登录请求是否成功
        if (response.status === 200) {
          this.$message({
            message: '注册成功',
            type: 'success'
          });
          this.$router.push('/home')
          window.location.replace("/");
        }

      },
      randomString(length) {
        //随机字符串
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      },
      clear() {
        this.registerForm.username = ''
        this.registerForm.password = ''
        this.registerForm.confirmPassword = ''
      }
  }
}
</script>


<style scoped>
.re{
  width: 90%;
  height: auto;
  background-color: #f0f2f5;
}


</style>
