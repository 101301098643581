import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import router  from "@/router";
import request from "@/api";
const app = createApp(App)
app.use(router)
app.use(ElementPlus)
app.config.globalProperties.$request = request //全局挂载request
app.mount('#app')
